import React from 'react'
import { Container, Row, Col, Image } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import photoProfil2 from "../../../assets/images/photoProfil2.png";
import photoProfil1 from "../../../assets/images/photoProfil1.png";
import profileDefault from "../../../assets/images/profileDefault.svg";
import "./Component.css";

const settingsReview = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

const Testimoni = () => {
    return (
        <Container fluid>
        <Row>
          <Col md={12}>
            <Container>
              <Row className="justify-content-center projectCounterContent">
                <Col md={6}>
                  <h4 className="text-center mb-5 testimonials">Testimonials</h4>
                  <Slider {...settingsReview}>
                    <div>
                      <Row>
                        <Col xs={4} lg={3}>
                          <Image
                            src={photoProfil2}
                            roundedCircle
                            width="100"
                          ></Image>
                        </Col>
                        <Col xs={8} lg={9}>
                          <p>
                          Pelayanan dari tim cocoba dev ok banget dan harga nya bersahabat banget, 
                          aplikasi yang saya butuhkan dan saya harapkan sesuai dengan 
                          keinginan saya walaupun dengan deadline yang mepet. 
                          Terimakasih tim cocoba dev. 
                          </p>
                          <span>Monik</span>
                          <br />
                          <span>Personal</span>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col xs={4} lg={3}>
                          <Image
                            src={photoProfil1}
                            roundedCircle
                            width="100"
                          ></Image>
                        </Col>
                        <Col xs={8} lg={9}>
                          <p>
                            Jasa pembuatan aplikasi di cocoba dev di luar ekspentasi saya, 
                            sangat keren dan aplikasi nya berjalan dengan sangat baik. 
                            Setelah menggunakan jasa di cocoba dev kegiatan pemilu organisasi di kampus saya pada saat masa pandemi 
                            berjalan baik secara online. Terima kasih cocoba dev.
                          </p>
                          <span>Fajar (IKA HI Unpad)</span>
                          <br />
                          <span>Mahasiswa</span>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col xs={4} lg={3}>
                          <Image
                            src={profileDefault}
                            roundedCircle
                            width="100"
                          ></Image>
                        </Col>
                        <Col xs={8} lg={9}>
                          <p>
                          Tampilan aplikasi booking pendakian gunung ciremai online nya sesuai harapan bagus sekali, 
                          dan proses-proses nya berjalan baik. 
                          Terima kasih atas pelayanan nya yang baik sekali
                          </p>
                          <span>Dion</span>
                          <br />
                          <span>Personal</span>
                        </Col>
                      </Row>
                    </div>
                  </Slider>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    )
}

export default Testimoni
