import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "./HomePage.css";
import Preview from "../../../assets/images/preview.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../../components/Footer/Footer";

import FeaturedProject from "../components/FeaturedProject";
import ContactUs from "../components/ContactUs";
import Testimoni from "../components/Testimoni";
import UsedTech from "../components/UsedTech";
import AboutUs from "../components/AboutUs";

import NavbarPage from "../../../components/Navbar/Navbar";
import { HashLink } from "react-router-hash-link";

const HomePage = () => {
  return (
    <>
      <NavbarPage />
      <Container>
        <Row className="justify-content-center">
          <Col md={6} style={{ padding: "50px" }}>
            <h3 className="animatedHeader">
              Plan.<br></br> Create.<br></br> Deploy.
            </h3>
            <p className="mt-5 title-cocoba">
              Buat Aplikasi Sesuai Dengan Kebutuhan Anda Bersama Cocoba Dev
            </p>
            <HashLink smooth to="#contact-us" style={{ color: "#fff" }}>
              <Button variant="outline-dark">Contact Us</Button>
            </HashLink>
          </Col>
          <Col md={6} className="text-center">
            <Image
              src={Preview}
              width="80%"
              className="mt-5 mb-5 mb-md-1 d-none d-md-block"
            ></Image>
          </Col>
        </Row>
      </Container>
      <AboutUs />
      <UsedTech />
      <FeaturedProject />
      <ContactUs />
      <Testimoni />
      <Footer />
    </>
  );
};

export default HomePage;
