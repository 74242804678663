import { Container, Row, Col, Card, CardColumns } from "react-bootstrap";

//portfolio image
import ashlin from "../../../assets/images/portfolio/ashlin.png";
import covidWeb from "../../../assets/images/portfolio/covidWeb.jpg";
import exploreKuningan from "../../../assets/images/portfolio/exploreKuningan.jpg";
import gelato from "../../../assets/images/portfolio/gelato.jpg";
import maison from "../../../assets/images/portfolio/maison.jpg";
import mekanik from "../../../assets/images/portfolio/mekanik.jpg";
import shibuya from "../../../assets/images/portfolio/shibuya.jpg";
import teknika from "../../../assets/images/portfolio/teknika.jpg";
import covidApp from "../../../assets/images/portfolio/covidApp.png";
import magangQa from "../../../assets/images/portfolio/magangQa.png";

const FeaturedProject = () => {
  return (
    <Container id="featured">
      <Row className="justify-content-center featured">
        <Col md={12} className="text-center">
          <h4 className="text-center mb-5 pb-5 featured">Featured Projects</h4>
        </Col>
        <Col md={12} style={{ display: "contents" }}>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={gelato}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">Website Gelato</Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Website yang menampilkan info dan rasa gelato yang tersedia.
                Tools : Codeigniter, Bootstrap, JQuery
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={ashlin}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">
                Ashlin Nepal Trekking Tour
              </Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Website yang menampilkan paket-paket liburan ke nepal serta
                melakukan booking, Tools: Codeigniter, Bootstrap, JQuery
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={shibuya}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">Shibuya Quarter</Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Website yang menampilkan informasi restaurant serta menu yang
                tersedia. Tools : Codeigniter, Bootstrap, JQuery
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={maison}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">Maison De Palmer</Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Website yang menampilkan produk handuk dari terry palmer. Tools
                : Codeigniter, Bootstrap, JQuery
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={mekanik}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">Mekanik Antik</Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Website yang menampilkan informasi mengenai perusahaan CV.
                Mekanik Antik. Tools : Bootstrap, JQuery
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={exploreKuningan}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">Explore Kuningan</Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Website booking pendakian gunung ciremai. Tools : PHP Native,
                Bootstrap, Jquery + Ajax
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={teknika}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">Teknika APP</Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Aplikasi pemesanan jasa pembuatan produk mekanik. Tools:
                Laravel, VueJS, Axios, Bootstrap, SASS, Firebase
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={covidWeb}
            />
            <Card.Body>
              <small className="card_subtitle">Website</small>
              <Card.Title className="title_card">
                Data Covid-19 di Indonesia
              </Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Website yang menampilkan informasi mengenai data penyebaran
                covid 19 di indonesia pada tahun 2020
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={covidApp}
            />
            <Card.Body>
              <small className="card_subtitle">Mobile Android</small>
              <Card.Title className="title_card">
                Covid-19 App
              </Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Aplikasi untuk menginformasikan data Covid-19 di berbagai
                belahan dunia. Tools: Android, Java, Volley, GSON, API
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card style={{ width: "18rem" }} className="featured_card">
            <Card.Img
              variant="top"
              className="featured_card_img"
              src={magangQa}
            />
            <Card.Body>
              <small className="card_subtitle">Mobile Android</small>
              <Card.Title className="title_card">
                Monitoring Magang QA
              </Card.Title>
              <Card.Subtitle className="mb-2text-muted featured-subtitle">
                Aplikasi monitoring kegiatan peserta magang di bagian Quality
                Assurance. Tools: Android, Java, Firebase <br />
                <br />
                <br />
                <br />
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturedProject;
