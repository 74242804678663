import { Container, Row, Col, Image } from "react-bootstrap";
import "./Footer.css";
import Logo from "../../assets/images/logo.svg";
import React from "react";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <Container fluid className="footer-container">
      <Row className="justify-content-center">
        <Col md={12}>
          <Container className="footer-body">
            <Row className="justify-content-center">
              <Col md={6}>
                <Row>
                  <Col md={12} className="mt-md-1">
                    <Image src={Logo} width="64"></Image>
                  </Col>
                  <Col md={12}>
                    <span className="text-light ">
                      Buat Aplikasi Sesuai Dengan <b> Kebutuhan Anda </b>
                      <br /> Bersama Cocoba Dev
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6} className="text-light mt-4 mt-md-1">
                    <h5>Cocoba Dev</h5>
                    <h6>
                      <HashLink smooth to="#featured" style={{ color: "#fff" }}>
                        Project
                      </HashLink>
                    </h6>
                    <h6>
                      <HashLink smooth to="#about" style={{ color: "#fff" }}>
                        About
                      </HashLink>
                    </h6>
                    <h6>
                      <HashLink
                        smooth
                        to="#contact-us"
                        style={{ color: "#fff" }}
                      >
                        Contact
                      </HashLink>
                    </h6>
                  </Col>
                  <Col md={6} className="text-light mt-4 mt-md-1">
                    <h5>Social Media</h5>
                    <a
                      className="text-light"
                      href="https://www.instagram.com/cocobadev/"
                    >
                      <h6>Instagram</h6>
                    </a>
                    <a
                      className="text-light"
                      href="https://www.linkedin.com/in/cocoba-dev-890986201/"
                    >
                      <h6>Linked In</h6>
                    </a>
                    <a
                      className="text-light"
                      href="https://www.facebook.com/cocoba.dev"
                    >
                      <h6>Facebook</h6>
                    </a>
                  </Col>
                  <Col md={12} className="mt-3 text-light">
                    <h5>Office</h5>
                    <h6>cocobadev@gmail.com</h6>
                    <h6>Bandung, Jawa Barat Indonesia</h6>
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="text-center mt-5 text-light ">
                <h5 className=" copyright">
                  &copy; 2021 Coco Bandung Developer - All rights reserved -
                  Plan Create Deploy
                </h5>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
