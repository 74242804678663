import { Container, Row, Col, Card, CardColumns, Image } from "react-bootstrap";

import emailIcon from "../../../assets/images/icons/email.svg";
import linkedInIcon from "../../../assets/images/icons/linkedin.svg";
import instagramIcon from "../../../assets/images/icons/instagram.svg";
import facebookIcon from "../../../assets/images/icons/facebook.svg";
import "./Component.css";

const ContactUs = () => {
  return (
    <Container className="projectCounter" id="contact-us" fluid>
      <Row>
        <Col md={12}>
          <Container>
            <Row className="justify-content-center projectCounterContent">
              <Col md={12} className="text-center mb-5">
                <h4 className="contactus ">Contact Us</h4>
                <h6 className="contactus-desc">
                  Click one of our contacts below to say hello.
                </h6>
              </Col>
              <Col md={3} className="text-center mt-md-1">
                <a href="mailto:cocobadev@gmail.com">
                  <Image src={emailIcon} width="64"></Image>
                  <br />{" "}
                </a>
                <p className="mt-3">Cocobadev@gmail.com</p>
              </Col>
              <Col md={3} className="text-center mt-5 mt-md-1">
                <a href="https://www.linkedin.com/in/cocoba-dev-890986201/">
                  <Image src={linkedInIcon} width="48"></Image>
                  <br />{" "}
                </a>
                <p className="mt-3">Cocoba Dev</p>
              </Col>
              <Col md={3} className="text-center  mt-5 mt-md-1">
                <a href="https://www.facebook.com/cocoba.dev">
                  <Image src={facebookIcon} width="48"></Image>
                  <br />{" "}
                </a>
                <p className="mt-3">Cocoba Dev</p>
              </Col>
              <Col md={3} className="text-center mt-5 mt-md-1">
                <a href="https://www.instagram.com/cocobadev/">
                  <Image src={instagramIcon} width="48"></Image>
                  <br />
                </a>
                <p className="mt-3">@cocobadev</p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
