import { Navbar, Nav, NavDropdown, FormControl, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logos from "../../assets/images/coco.png";

import { HashLink } from "react-router-hash-link";

const NavbarPage = () => {
  return (
    <Navbar expand="lg" sticky="top" className="navbar_glass">
      <Navbar.Brand className="mx-5" href="#home">
        {" "}
        <Image src={Logos} width="100"></Image>{" "}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="mx-5">
        <Nav className="ml-auto">
          <HashLink smooth to="#about" className="navbar_link mr-3">
            About Us
          </HashLink>
          <HashLink smooth to="#contact-us" className="navbar_link mr-3">
            Contact Us
          </HashLink>
          <HashLink smooth to="#featured" className="navbar_link mr-3">
            Our Project
          </HashLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarPage;
