import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Component.css";

//images
import androidIcon from "../../../assets/images/icons/android.svg";
import firebaseIcon from "../../../assets/images/icons/firebase.svg";
import bootstrapIcon from "../../../assets/images/icons/bootstrap.svg";
import cssIcon from "../../../assets/images/icons/css.svg";
import htmlIcon from "../../../assets/images/icons/html.svg";
import jsIcon from "../../../assets/images/icons/js.svg";
import laravelIcon from "../../../assets/images/icons/laravel.svg";
import phpIcon from "../../../assets/images/icons/php.svg";
import flutterIcon from "../../../assets/images/icons/flutter.svg";
import nextIcon from "../../../assets/images/icons/nextjs.svg";
import reactIcon from "../../../assets/images/icons/react.svg";
import reduxIcon from "../../../assets/images/icons/redux.svg";

const UsedTech = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <Container className="projectCounter" fluid>
      <Row>
        <Col md={12}>
          <Container>
            <Row className="justify-content-center projectCounterContent">
              <Col md={8}>
                <h4 className="text-center mb-5 usedtech">Used Technology</h4>
                <Slider {...settings}>
                  <div>
                    <Image src={androidIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={firebaseIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={bootstrapIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={cssIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={htmlIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={jsIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={laravelIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={phpIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={flutterIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={nextIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={reactIcon} width="160"></Image>
                  </div>
                  <div>
                    <Image src={reduxIcon} width="160"></Image>
                  </div>
                </Slider>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default UsedTech;
