import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Component.css";

const AboutUs = () => {
  return (
    <>
      <Container className="projectCounter" id="about" fluid>
        <Row>
          <Col md={12}>
            <Container>
              <Row className="justify-content-center projectCounterContent">
                <Col md={2} className="text-center">
                  <h1 className="number-cocoba mt-md-1">10</h1>
                  <span className="number-title">Clients</span>
                </Col>
                <Col md={2} className="text-center mt-5 mt-md-1">
                  <h1 className="number-cocoba">10</h1>
                  <span className="number-title">Websites Created</span>
                </Col>
                <Col md={2} className="text-center mt-5 mt-md-1">
                  <h1 className="number-cocoba">4</h1>
                  <span className="number-title">Mobile Apps Created</span>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center why-us ">
          <Col md={6} className="text-center mt-md-1">
            <h4 className="aboutus">Who Are We?</h4>
            <span className="aboutus-title">
              Cocoba Dev is an Application Development Team based in Bandung,
              Indonesia.
            </span>
          </Col>
          <Col md={6} className="text-center mt-5 mt-md-1">
            <h4 className="aboutus">Why Should We?</h4>
            <span className="aboutus-title">
              Make applications according to customer requirements Make your
              subscription system easy
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
